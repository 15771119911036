<template>
	<div id="body">
		<div id="coobanner">
			<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/ABUSBANNER.jpg">
		</div>
		<div id="cooper_main">
			<p id="tit">为什么选择我们？</p>
			<p id="why_txt">
				1、大总管信息管理平台，是一个开放的、包容的平台您只要加入我们的平台即可成为我们大总管的终身合作伙伴，您将拥有一个终身的推荐码，只要是您推荐的客户一旦成交，大总管平台将实时把您佣金按照比例以微信红包的方式返给您，您的客户每年的续费，将按照比例实时返现给您，让您实现可持续，可累计的收入，让您轻松拥有一个可长期回报的平台。
			</p>
			<p id="tit">大总管合作伙伴佣金返还比例！</p>
			<div id="BORBOX">
				<el-table :data="tableData" border style="width: 100%">
					<el-table-column prop="name" label="佣金" width="140">
					</el-table-column>
					<el-table-column prop="formula" label="提成公式">
					</el-table-column>
				</el-table>
			</div>
			<img id="flow_ing" src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/FYIMG.jpg">
			<div style="margin-top: 30px;font-size: 12px;text-align: right;width: 80%;">
				返佣比例会根据国家税务部门实收税率（含企业增值税、附加税、企业所得税等）占比会有调整，本公司会在公司官网中通告如实说明，特此告知！
			</div>
			<p id="tit">如何提现？</p>
			<div id="TXbox">
				<p>第一步：关注大总管微信公众号</p>
				<img style="width: 200px;" src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/wx.png">
				<p>第二步：您推荐的客户付款成功后，大总管销售管家小程序将自动按佣金比例返现到您绑定的支付宝帐号上</p>
				<div id="imglist">
					<div id="imglist_box">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/wx01.jpg">
						<p>代理/客户登录小程序</p>
					</div>
					<div id="i">
						<i class="el-icon-arrow-right"></i>
					</div>
					<div id="imglist_box">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/wx02.jpg">
						<p>代理/客户发起提现</p>
					</div>
					<div id="i">
						<i class="el-icon-arrow-right"></i>
					</div>
					<div id="imglist_box">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/wx03.jpg">
						<p>转账存入零钱</p>
					</div>
				</div>
			</div>
			<p id="tit">免费注册成为代理！</p>
			<div id="frombox">
				<el-form :model="numberValidateForm" ref="numberValidateForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="手机号" prop="Phone" :rules="[
				      { required: true, message: '手机号不能为空'},
				      { type: 'number', message: '手机号必须为数字值'}
				    ]">
						<el-input type="Phone" v-model.number="numberValidateForm.Phone" placeholder="请输入手机号"
							autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item label="邀请码" prop="YQcode">
						<el-input type="YQcode" v-model.number="numberValidateForm.YQcode" placeholder="选填"
							autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item label="姓名" prop="Name" :rules="[
					    { required: true, message: '姓名不能为空'},
					  ]">
						<el-input type="Phone" v-model.number="numberValidateForm.Name" placeholder="请输入真实姓名,否则收不到返佣"
							autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item label="验证码" prop="Code" :rules="[
					    { required: true, message: '验证码不能为空'},
					  ]">
						<el-input type="Phone" v-model.number="numberValidateForm.Code" placeholder="请输入验证码"
							autocomplete="off">
							<template slot="append">
								<span style="cursor: pointer;" @click="Hqcodefun">{{hqtxt}}</span>
							</template>
						</el-input>
					</el-form-item>
					<el-form-item>
						<!-- <el-button type="primary" >注册</el-button> -->
						<button id="zcbtn" type="button" @click="submitForm('numberValidateForm')">注册</button>
					</el-form-item>
				</el-form>
				<el-radio v-model="radio" label="1"><span>服务条款</span></el-radio>
				<span id="XXtext" v-if='wid>720' @click="centerDialogVisible=true">点击查看</span>
				<span id="XXtext" v-else @click="centerDialogVisibles=true">点击查看</span>
			</div>
		</div>
		<el-dialog title="服务条款" :visible.sync="centerDialogVisible" width="30%" center>
			<div id="txt">
				<p>1、欢迎您成为大总管信息管理平台的合作伙伴，成为我公司的合作伙伴需要您拥有独立的手机号和微信号，要关注我们大总管的公众号以方便您的佣金提成的实现。</p>
				<p>2、成为我公司的合作伙伴后我们系统平台的所有交易均需在我公司平台上完成如私下独自收客户钱款而不通过公司平台进行注册交易的产生的纠纷问题我公司概不负责。</p>
				<p>3、公司佣金比例的调整解释权归大总管（上海）科技有限公司所有。</p>
				<p>4、如您注册成功即视为您同意我公司成为代理的合作条件，感谢您的参与如您有任合疑问欢迎您随时致电我公司服务电话</p>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="服务条款" :visible.sync="centerDialogVisibles" width="98%" center>
			<div id="txt">
				<p>1、欢迎您成为大总管信息管理平台的合作伙伴，成为我公司的合作伙伴需要您拥有独立的手机号和微信号，要关注我们大总管的公众号以方便您的佣金提成的实现。</p>
				<p>2、成为我公司的合作伙伴后我们系统平台的所有交易均需在我公司平台上完成如私下独自收客户钱款而不通过公司平台进行注册交易的产生的纠纷问题我公司概不负责。</p>
				<p>3、公司佣金比例的调整解释权归大总管（上海）科技有限公司所有。</p>
				<p>4、如您注册成功即视为您同意我公司成为代理的合作条件，感谢您的参与如您有任合疑问欢迎您随时致电我公司服务电话</p>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisibles = false">取 消</el-button>
				<el-button type="primary" @click="centerDialogVisibles = false">确 定</el-button>
			</span>
		</el-dialog>
		<footers></footers>
	</div>
</template>

<script>
	const axi = require('../assets/axi.js')
	import footers from './FOOTER.vue'
	export default {
		components: {
			footers
		},
		data() {
			return {
				tableData: [{
					formula: '第一年：（推荐客户成交金额*36.8%）,第二年：（推荐客户成交金额*27.6%）,第三年及后续：（推荐客户成交金额*18.4%）',
					name: '一级返佣',
				}, {
					formula: '第一年：（（推荐客户成交金额*36.8%）*80%）,第二年：（（推荐客户成交金额*27.6%）*80%）,第三年及后续：（（推荐客户成交金额*18.4%）*80%）',
					name: '二级返佣',
				}, {
					formula: '第一年：（（推荐客户成交金额*36.8%）*20%）,第二年：（（推荐客户成交金额*27.6%）*20%）,第三年及后续：（（推荐客户成交金额*18.4%）*20%）',
					name: '三级返佣',
				}, ],
				numberValidateForm: {
					Phone: '',
					YQcode: "",
					Code: "",
					Name: ""

				},
				radio: '',
				centerDialogVisible: false,
				centerDialogVisibles: false,
				wid: '',
				hqtxt: '获取验证码',
				Sen: 60,
			}
		},
		mounted() {
			this.wid = window.innerWidth
			if (this.$route.query.code) {
				this.numberValidateForm.YQcode = this.$route.query.code
				document.querySelector('#frombox').scrollIntoView({
					behavior: "smooth", // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
					block: "center", // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
					inline: "nearest" // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
				})
			}
		},
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {

						if (!this.radio) {
							// this.$message.error('请先同意服务条款')
							this.$alert('请先同意服务条款', '服务条款', {
								confirmButtonText: '确定',
								// callback: action => {
								//   this.$message({
								//     type: 'info',
								//     message: `action: ${ action }`
								//   });
								// }
							});
						} else {
							// alert('submit!');
							let data = {
								Phone: this.numberValidateForm.Phone,
								InvitationCode: this.numberValidateForm.YQcode,
								UserName: this.numberValidateForm.Name,
								Code: this.numberValidateForm.Code
							}
							console.log(data)
							axi.post('Register/RegisterAgentUser', data).then((res) => {
								console.log(res)
								if (res.data.Code == 1000) {
									this.$message.success('注册成功！')
									this.numberValidateForm.Code = ''
									this.numberValidateForm.Name = ''
									this.numberValidateForm.YQcode = ''
									this.numberValidateForm.Phone = ''
									if(window.innerWidth<720){
										this.$router.push('/WeChat')
									}else{
										this.$message.info('请关注微信公众号绑定身份信息！')
									}
								} else {
									this.$message.error(res.data.Msg)
								}
							})
						}
					} else {
						return false;
					}
				});
			},
			Hqcodefun() {
				if (/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.numberValidateForm.Phone)) {
					if (this.Sen < 60) {} else {
						// console.log(123)
						axi.post('Register/PhoneCode?Phone=' + this.numberValidateForm.Phone).then((res) => {
							console.log(res)
							if (res.data.Code != 1000) {
								this.$message.error(res.data.Msg)
								if (res.data.Dto.NextTime) {
									res.data.Dto.NextTime = res.data.Dto.NextTime.split('.')[0].replace('T', ' ')
									let dats = new Date()
									let Ndates = new Date(res.data.Dto.NextTime)
									let Sens = Ndates - dats
									Sens = Sens / 1000
									console.log(Sens)
									this.Sen = Sens.toFixed(0)
								}
							}
						})
						let time = setInterval(() => {
							this.Sen = this.Sen - 1
							if (this.Sen < 1) {
								this.hqtxt = '获取验证码'
								clearTimeout(time);
								this.Sen = 60
							} else {
								this.hqtxt = '(' + this.Sen + ')重新获取'
							}
						}, 1000)
					}
				} else {
					this.$message.error('请输入正确的手机号')
				}
			}
		}
	}
</script>

<style scoped="scoped" lang="less">
	//电脑样式
	@media screen and(min-width:720px) {
		#coobanner {
			width: 100%;
			height: 700px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		#cooper_main {
			width: 80%;
			margin-left: 10%;
			// height: 300px;
			margin-bottom: 30px;
			// background-color: #FFFF00;
			text-align: center;

			#tit {
				margin-top: 50px;
				font-size: 36px;
				font-weight: bold;
			}

			#TXbox {
				width: 60%;
				margin-left: 20%;

				p {
					font-weight: bold;
				}

				#imglist {
					width: 100%;
					height: 400px;
					display: flex;
					align-items: center;
					justify-content: space-between;

					#imglist_box {
						width: 30%;
						height: 100%;

						img {
							height: 350px;
							margin-top: 20%;
						}

						p {
							margin-top: 20px;
						}
					}

					#i {
						width: 5%;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 50px;
						color: #D8D8D8;
					}
				}
			}

			#frombox {
				width: 40%;
				margin-left: 30%;
				margin-top: 60px;
				
				#zcbtn {
					width: 50%;
					height: 50px;
					background-color: #fd3f31;
					color: #FFFFFF;
					font-size: 18px;
					border-radius: 30px;
				}
			}

			#why_txt {
				width: 60%;
				margin-left: 20%;
				font-size: 20px;
			}

			#BORBOX {
				width: 60%;
				margin-left: 20%;
				margin-top: 60px;
			}

			#flow_ing {
				width: 60%;
				margin-top: 30px;
			}
		}

		#XXtext {
			font-size: 13px;
			margin-left: -20px;
			color: #0055ff;
			cursor: pointer;
		}
	}

	// 手机样式
	@media screen and(max-width:720px) {
		#coobanner {
			width: 100%;
			height: 200px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		#cooper_main {
			width: 100%;
			// height: 300px;
			margin-bottom: 30px;
			// background-color: #FFFF00;
			text-align: center;

			#tit {
				margin-top: 50px;
				font-size: 26px;
				font-weight: bold;
				text-align: center;
			}

			#TXbox {
				width: 90%;
				margin-left: 5%;

				p {
					font-weight: bold;
				}

				img {
					width: 60%;
					max-height: 100%;
				}

				#imglist {
					width: 100%;
					height: 160px;
					display: flex;
					align-items: center;
					justify-content: space-between;

					#imglist_box {
						width: 30%;
						height: 100%;

						img {
							height: 120px;
							margin-top: 20%;
						}

						p {
							margin-top: 20px;
						}
					}

					#i {
						width: 5%;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 50px;
						color: #D8D8D8;
					}
				}
			}

			#frombox {
				width: 90%;
				// margin-left: 5%;
				margin-top: 60px;

				#zcbtn {
					width: 80%;
					height: 50px;
					background-color: #fd3f31;
					color: #FFFFFF;
					font-size: 18px;
					border-radius: 30px;
					margin-left: -5%;
				}
			}

			#why_txt {
				width: 90%;
				margin-left: 5%;
				font-size: 16px;
			}

			#BORBOX {
				width: 100%;
				margin-top: 60px;
			}

			#flow_ing {
				width: 90%;
				margin-top: 30px;
			}
		}

		#XXtext {
			font-size: 13px;
			margin-left: -20px;
			color: #0055ff;
			cursor: pointer;
		}
	}
</style>
